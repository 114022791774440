import React from "react";
import {
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentTitle,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./blog.css";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "blogs/blog2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title={"Security Measures to Keep You Safe in Your New Home"} />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={data.BlogImage.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed>
          <ContentTitle
            title={"Security Measures to Keep You Safe in Your New Home"}
          />
          <div className={"cp-blog"}>
            <p>
              <b>
                Homeownership is a great opportunity, so you want to make sure
                you’re doing everything you can to protect it.
              </b>{" "}
              Home security is always treated as a top priority and taken into
              consideration whenever you feel unsafe at your place, the interest
              in home security features has tremendously increased. But that
              doesn’t mean you have to install all electronic security measures.
              There are some of the effective precautions against theft which
              you should use to secure your home and the three main factors are
              time, sound and visibility. This guide will extensively help you
              prevent property crime from happening and taking these precautions
              in action will not only protect your belongings but also yourself
              and your family.{" "}
            </p>

            <p>
              <h4>Secure the doors</h4>
            </p>

            <p>
              Ensure that whichever door or window locks you have, they are
              locked. Burglars mostly take advantage of the unlocked door or
              open window as it simply provides easy access to them without any
              hassle. You can add window bars and reinforce glass with window
              security film, or install window or glass break sensors.
            </p>

            <p>
              <h4>Light up the landscape</h4>
            </p>

            <p>
              No criminal likes to be in the spotlight. By keeping ample outdoor
              lighting around your front and back yards, along pathways and near
              the garage can keep them away. Not only you will make intruders
              skittish, but also cut down your risk of a stumble on your way up
              the front steps. To make your outdoor security lights even more
              effective use motion-activated lights or save energy with
              solar-powered lights.{" "}
            </p>

            <p>
              <h4>Don’t forget the garage</h4>
            </p>

            <p>
              You might tend to keep some of your unused items in your garage
              which can be quite precious and important to you, and if criminals
              can’t access inside your home, the garage can also be a good
              option. You should make sure to lock all your doors to your garage
              properly and also secure it by covering the windows so that no one
              can peek into it. For extra security, you can even upgrade to a
              smart garage door opener.
            </p>

            <p>
              <h4>Don’t accept unsolicited help</h4>
            </p>

            <p>
              Until and unless you call someone and ask for help, don’t let
              anyone in. Many thieves are a con artist who may come as a
              plumber, painter gardener, etc, usually with another person who
              may distract you and keep busy while the other one does its job.{" "}
            </p>

            <p>
              <h4>Get to know your neighbor</h4>
            </p>

            <p>
              This might seem like one of those things you just do when you move
              into a new home, but have you ever considered getting to know your
              neighbors as a safety measure? More eyes watching your home simply
              means more security. A great way to ward off burglars is to get
              friendly with your neighbors, which can especially be helpful when
              you go away for an extended period.
            </p>

            <p>
              <h4>Eliminate hiding places</h4>
            </p>

            <p>
              Keeping your home well-kept and eliminating potential hiding spots
              signifies thieves that the home is lived in. Now and then you
              should trim down trees and plants close to your home that could be
              used for cover. And also if you have trees near windows, either
              remove them or reinforce those windows with extra security. By
              leaving pricey goods on display in the yard tempts thieves even
              more so keep your outdoor as simple and up to date as possible{" "}
            </p>

            <p>
              <h4>Get a dog</h4>
            </p>

            <p>
              Unwanted attention is what burglars hate the most. They want their
              work to be done in utter silence so that no one can catch them.
              But we don’t want that right? So keeping a dog would be a great
              idea. They often bark when they sense unfamiliar and unknown
              people entering your doorstep, warding off potential burglars.
            </p>

            <p>
              <h4>Add security cameras</h4>
            </p>

            <p>
              If you are not considering getting a dog, then installing security
              cameras is a must.&nbsp; Cameras speak for themselves as a
              deterrent but also serves other purposes. Use a security camera
              with a mobile app so that you can see the footage whenever and
              wherever you go. Other must-have features are motion detection,
              night vision Wi-Fi capability, and weatherproof casing.{" "}
            </p>

            <p>
              <h4>Be smart about who you let into your home</h4>
            </p>

            <p>
              Not only strangers, but even your servant, maid, or gardener can
              have ulterior motives. So you should never trust anyone and always
              keep an eye on people you hire to work in your home. Letting
              anyone inside is allowing them to steal. So gather out your
              valuables and keep them somewhere out of reach.
            </p>

            <p>
              <h4>Install a home security Alarm System</h4>
            </p>

            <p>
              The best and most effective way to protect your home is to install
              a home security system and currently, there is a limited offer
              which provides free security alarm system and video intercom with
              every contract signed in 2019 with Grandeur Homes. You don’t have
              to do everything at once, identify which strategies are most
              important to you. And make a plan to add the rest later and having
              emergency contacts available in the event something unfortunate
              occurs is also an important step.
            </p>
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          primaryLink={InternalLinkTypes.GetStarted}
          primaryText={"Get Started"}
          secondaryLink={InternalLinkTypes.Blogs}
          secondaryText={"Read other blogs"}
        />
      </section>
    </Layout>
  );
};

export default Blog;
